const convertStyle = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};
document.addEventListener('resize', convertStyle);
document.addEventListener('DOMContentLoaded', convertStyle);

document.addEventListener('DOMContentLoaded', function () {

    let cookieConsent = document.querySelector('.cookie-consent-container');
    let cookieConsentForm = document.querySelector('.cookie-consent__form');
    let cookieConsentFormBtn = document.querySelectorAll('.cookie-consent__btn');
    let cookieConsentCategoryDetails = document.querySelector('.cookie-consent__category-group');
    let cookieConsentCategoryDetailsToggle = document.querySelector('.cookie-consent__toggle-details');

    if (cookieConsentForm) {

        // Submit form via ajax
        cookieConsentFormBtn.forEach(function (btn) {
            btn.addEventListener('click', function (event) {
                event.preventDefault();

                const formData = new FormData(cookieConsentForm);
                formData.append(btn.name, '');

                fetch(cookieConsentForm.action, {
                    method: 'POST',
                    body: formData
                }). then(response => {
                    if (response.ok) {
                        cookieConsent.remove();
                    }
                });

            }, false);
        });
    }

    if (cookieConsentCategoryDetails && cookieConsentCategoryDetailsToggle) {
        cookieConsentCategoryDetailsToggle.addEventListener('click', function () {
            const detailsIsHidden = cookieConsentCategoryDetails.style.display !== 'block';
            cookieConsentCategoryDetails.style.display = detailsIsHidden ? 'block' : 'none';
            cookieConsentForm.querySelectorAll('.cookie-consent__toggle-details-hide').forEach(function (el) {
                el.style.display = detailsIsHidden ? 'block' : 'none';
            });
            cookieConsentForm.querySelectorAll('.cookie-consent__toggle-details-show').forEach(function (el) {
                el.style.display = detailsIsHidden ? 'none' : 'block';
            });
        });
    }
});
